html {
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

body {
    background-color: $white;
}

// Font styles // 

h1 {
    font-size: 5.5rem;
    font-weight: normal;
    font-family: 'Helvetica Neue', sans-serif;
}

h2 {
    font-size: 4.5rem;
    font-weight: normal;
    font-family: 'Helvetica Neue', sans-serif;
}

h3 {
    font-size: 3.8rem;
    font-weight: normal;
    font-family: 'Helvetica Neue', sans-serif;
}

h4 {
    font-size: 3.1rem;
    font-weight: normal;
    font-family: 'Helvetica Neue', sans-serif;
}

h5 {
    font-size: 2.5rem;
    font-weight: normal;
    font-family: 'Helvetica Neue', sans-serif;
}

h6 {
    font-size: 2.5rem;
    font-weight: normal;
    margin: 15px 0;
    font-family: 'Helvetica Neue', sans-serif;
}

p {
     font-size: 1.6rem;
     font-weight: normal;
     font-family: 'Helvetica Neue', sans-serif;
}

a {
    font-weight: normal;
    font-family: 'Oswald', sans-serif;
}

li {
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
}