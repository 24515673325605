/* PLUGINS/PACKAGES */
/* Vendors */
@import 'vendors/_normalize.css';
/* BASE STYLES */
html {
  font-size: 62.5%;
  /* reduces default browser size of 16px to 10px */
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  position: relative;
}
body.no-scroll {
  overflow: hidden;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

html {
  font-size: 62.5%;
  /* reduces default browser size of 16px to 10px */
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: #ffffff;
}

h1 {
  font-size: 5.5rem;
  font-weight: normal;
  font-family: "Helvetica Neue", sans-serif;
}

h2 {
  font-size: 4.5rem;
  font-weight: normal;
  font-family: "Helvetica Neue", sans-serif;
}

h3 {
  font-size: 3.8rem;
  font-weight: normal;
  font-family: "Helvetica Neue", sans-serif;
}

h4 {
  font-size: 3.1rem;
  font-weight: normal;
  font-family: "Helvetica Neue", sans-serif;
}

h5 {
  font-size: 2.5rem;
  font-weight: normal;
  font-family: "Helvetica Neue", sans-serif;
}

h6 {
  font-size: 2.5rem;
  font-weight: normal;
  margin: 15px 0;
  font-family: "Helvetica Neue", sans-serif;
}

p {
  font-size: 1.6rem;
  font-weight: normal;
  font-family: "Helvetica Neue", sans-serif;
}

a {
  font-weight: normal;
  font-family: "Oswald", sans-serif;
}

li {
  font-weight: normal;
  font-family: Helvetica, Arial, sans-serif;
}

/* COMPONENTS */
header {
  background-color: #003057;
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
}
@media (min-width: 768px) {
  header {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: center;
  }
}
header .lang-anchors {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 12rem;
}
header .lang-anchors .dropdown-content {
  display: none;
  position: relative;
  top: inherit;
  right: 0;
  width: 100%;
}
@media (min-width: 768px) {
  header .lang-anchors .dropdown-content {
    position: absolute;
    top: 42px;
  }
}
header .lang-anchors .active {
  display: block;
}
header .lang-anchors:hover .lang-anchor--primary::before {
  transform: rotate(-90deg);
  transition: ease 0.25s;
}
header .lang-anchor {
  color: #6FB1C8;
  display: inline-flex;
  font-family: "roboto", sans-serif;
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
  margin-left: auto;
  border: 3px solid #6FB1C8;
  padding: 0.5rem 4rem 0.5rem 2rem;
  position: relative;
  align-items: center;
  transition: ease-in-out 0.25s;
  background: transparent;
  width: 100%;
  margin: 0.25rem 0;
}
@media (min-width: 768px) {
  header .lang-anchor {
    margin-left: initial;
    margin: 0;
  }
}
header .lang-anchor--primary::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-image: url("/Assets/icons/cta-icon.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 2rem;
}
header .logo-container {
  display: flex;
  flex-direction: row;
  margin: 4rem auto 2rem auto;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  header .logo-container {
    margin: 1rem 10rem 1rem 0;
  }
}
header .logo-container .logo {
  width: 45%;
}
header .logo-container .logo:nth-child(1) {
  border-right: 1px solid #ffffff;
  padding-right: 1.5rem;
}
header .logo-container .logo img {
  width: 100%;
  height: auto;
}

.brands-container {
  width: 90%;
  margin: 2rem auto;
}
@media (min-width: 768px) {
  .brands-container {
    max-width: 1080px;
    margin: 5rem auto;
  }
}
@media (min-width: 1024px) {
  .brands-container {
    width: 70%;
    margin: 5rem auto;
  }
}
.brands-container-header {
  text-align: center;
  border-bottom: 2px solid #6FB1C8;
}
@media (min-width: 768px) {
  .brands-container-header {
    border-bottom: none;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}
.brands-container-header hr {
  display: none;
}
@media (min-width: 768px) {
  .brands-container-header hr {
    display: block;
    height: 2px;
    background-color: #6FB1C8;
    border: none;
    width: 100%;
  }
}
.brands-container-header h2 {
  font-size: 3.6rem;
  font-weight: 600;
  color: #003057;
  margin: 1rem 0;
}
@media (min-width: 768px) {
  .brands-container-header h2 {
    min-width: fit-content;
    margin: 0;
  }
}
.brands-container .brands-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.brands-container .brands-section .brand {
  width: 40%;
  margin: 3rem auto;
}
@media (min-width: 768px) {
  .brands-container .brands-section .brand {
    width: 14%;
    margin: 0 auto;
    padding: 3rem;
  }
}
@media (min-width: 1024px) {
  .brands-container .brands-section .brand {
    width: 16%;
  }
}
@media (min-width: 1200px) {
  .brands-container .brands-section .brand {
    width: 18%;
  }
}
.brands-container .brands-section .brand img {
  width: 100%;
  height: auto;
}

.banner {
  width: 100%;
  background-color: #FEED01;
  padding: 4rem 0;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .banner {
    padding: 2rem 0;
  }
}
.banner .banner-copy-container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .banner .banner-copy-container {
    max-width: 1080px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.banner .banner-copy-container .banner-copy {
  text-align: center;
}
@media (min-width: 768px) {
  .banner .banner-copy-container .banner-copy {
    text-align: left;
    width: 50%;
  }
}
.banner .banner-copy-container .banner-copy h3 {
  font-size: 3.6rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
}
@media (min-width: 768px) {
  .banner .banner-copy-container .banner-copy h3 {
    margin: 0;
  }
}
.banner .banner-copy-container .banner-copy p {
  font-size: 1.8rem;
  line-height: 1.75;
}
@media (min-width: 768px) {
  .banner .banner-copy-container .banner-copy p {
    margin: 0;
    line-height: 1;
  }
}
.banner .banner-copy-container .banner-cta {
  color: #FEED01;
  background-color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 1rem 1.5rem;
}
@media (min-width: 768px) {
  .banner .banner-copy-container .banner-cta {
    max-width: 35%;
    margin: 0 auto;
  }
}

.banner-full {
  width: 100%;
  background: url("/Assets/mobile-banner-new.jpg"), linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 47rem;
  background-blend-mode: overlay;
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}
@media (min-width: 768px) {
  .banner-full {
    background: url("/Assets/Viega-group-shot.jpg"), linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  }
}
.banner-full-copy {
  color: #ffffff;
  width: 87%;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  .banner-full-copy {
    text-align: left;
  }
}
@media (min-width: 1440px) {
  .banner-full-copy {
    width: 63%;
  }
}
.banner-full-copy h2 {
  font-size: 3.6rem;
  margin: 0;
  line-height: 1;
  font-weight: 300;
  font-family: "roboto", sans-serif;
}
@media (min-width: 768px) {
  .banner-full-copy h2 {
    font-size: 6rem;
  }
}
.banner-full-copy h2:nth-of-type(2) {
  font-weight: 600;
  margin-bottom: 4rem;
}
.banner-full-copy a {
  text-decoration: none;
  font-size: 2.4rem;
  color: #000000;
  background-color: #6FB1C8;
  font-family: "roboto", sans-serif;
  font-weight: 600;
  padding: 0.75rem 4rem;
}

.info-section {
  width: 100%;
  background-color: #003057;
  margin-top: 0.5rem;
}
.info-section .info-container {
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .info-section .info-container {
    max-width: 1080px;
  }
}
.info-section .info-container .info-copy-container {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 768px) {
  .info-section .info-container .info-copy-container {
    flex-direction: row;
  }
}
.info-section .info-container .info-copy-container .info-copy {
  color: #ffffff;
  text-align: center;
  padding: 0 1rem;
}
@media (min-width: 768px) {
  .info-section .info-container .info-copy-container .info-copy {
    text-align: left;
    width: 80%;
  }
}
.info-section .info-container .info-copy-container .info-copy h3 {
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1;
  color: #6FB1C8;
  margin: 1rem 0 0 0;
}
.info-section .info-container .info-copy-container .info-copy p {
  line-height: 1.5;
}
.info-section .info-container .info-copy-container .info-copy p:nth-of-type(1) {
  margin-top: 0.5rem;
  margin-bottom: 4rem;
}
@media (min-width: 768px) {
  .info-section .info-container .info-copy-container .info-copy p:nth-of-type(1) {
    margin-bottom: 2rem;
  }
}
.info-section .info-container .info-copy-container .info-copy p:nth-of-type(2) {
  font-weight: 600;
  max-width: 75%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .info-section .info-container .info-copy-container .info-copy p:nth-of-type(2) {
    margin: initial;
    width: initial;
  }
}
.info-section .info-container .info-copy-container .info-icon {
  width: 16rem;
  margin: 0 auto;
}
.info-section .info-container .info-copy-container .info-icon img {
  width: 100%;
  height: auto;
}
.info-section .info-container .icon-section {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}
@media (min-width: 768px) {
  .info-section .info-container .icon-section {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .info-section .info-container .icon-section {
    flex-direction: row;
    margin-top: 3rem;
    text-align: left;
    justify-content: space-between;
  }
}
.info-section .info-container .icon-section .icon-container {
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  .info-section .info-container .icon-section .icon-container {
    flex-direction: row;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1024px) {
  .info-section .info-container .icon-section .icon-container {
    margin-bottom: initial;
  }
}
.info-section .info-container .icon-section .icon-container .icon {
  width: 10rem;
}
@media (min-width: 768px) {
  .info-section .info-container .icon-section .icon-container .icon {
    width: 7rem;
    padding: 0 1rem;
  }
}
.info-section .info-container .icon-section .icon-container .icon img {
  width: 100%;
  height: auto;
}
.info-section .info-container .icon-section .icon-container .icon-copy {
  color: #6FB1C8;
}
.info-section .info-container .icon-section .icon-container .icon-copy a {
  text-decoration: none;
  color: #6FB1C8;
}
.info-section .info-container .icon-section .icon-container .icon-copy p {
  margin: 0.5rem 0;
  font-size: 1.8rem;
}

.lists {
  background-color: #000000;
}
.lists--alt {
  min-height: 60rem;
  background-color: #003057;
  display: flex;
  padding-top: 4rem;
}
@media (min-width: 768px) {
  .lists--alt {
    padding: 0;
  }
}
.lists .list-section {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (min-width: 768px) {
  .lists .list-section {
    flex-direction: row-reverse;
    max-width: 1080px;
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media (min-width: 768px) {
  .lists .list-section .list-container {
    max-width: 60%;
  }
}
.lists .list-section .list-container--alt {
  max-width: 90%;
  align-self: center;
}
@media (min-width: 768px) {
  .lists .list-section .list-container--alt {
    max-width: 70%;
  }
}
.lists .list-section .list-container .list-container-copy {
  color: #ffffff;
  text-align: center;
  font-family: "roboto", sans-serif;
}
@media (min-width: 768px) {
  .lists .list-section .list-container .list-container-copy {
    text-align: left;
  }
}
.lists .list-section .list-container .list-container-copy h3 {
  font-family: "roboto", sans-serif;
  font-size: 3.6rem;
  color: #FEED01;
  font-weight: 600;
}
.lists .list-section .list-container .list-container-copy p {
  font-family: "roboto", sans-serif;
  font-size: 1.6rem;
}
.lists .list-section .list-container .list-container-copy--alt h3 {
  color: #6FB1C8;
  font-size: 3.6rem;
  margin: 1rem 0;
}
.lists .list-section .list-container .lists-container--alt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .lists .list-section .list-container .lists-container--alt {
    flex-direction: row;
  }
}
.lists .list-section .list-container .lists-container .list-item {
  color: #ffffff;
  text-align: center;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .lists .list-section .list-container .lists-container .list-item {
    flex-direction: row;
    text-align: left;
  }
}
.lists .list-section .list-container .lists-container .list-item--alt {
  flex-direction: column;
  text-align: center;
  width: initial;
  border-right: none;
}
.lists .list-section .list-container .lists-container .list-item--alt:nth-of-type(3) {
  border: none;
}
@media (min-width: 768px) {
  .lists .list-section .list-container .lists-container .list-item--alt {
    width: 25%;
    border-right: 1px solid #EAEAEA;
    padding-right: 3rem;
  }
}
.lists .list-section .list-container .lists-container .list-item .list-img {
  margin: 2rem 0;
}
@media (min-width: 768px) {
  .lists .list-section .list-container .lists-container .list-item .list-img {
    margin: 0 2rem 0 0;
  }
}
.lists .list-section .list-container .lists-container .list-item .list-img--alt {
  margin: 1rem 0;
}
.lists .list-section .list-container .lists-container .list-item h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
}
.lists .list-section .list-container .lists-container .list-item p {
  font-size: 1.6rem;
  margin: 0;
}
.lists .list-section .anchor-container {
  display: none;
  margin-top: 5rem;
}
@media (min-width: 768px) {
  .lists .list-section .anchor-container {
    display: block;
  }
}
.lists .list-section .anchor-container a {
  text-decoration: none;
  font-size: 2.4rem;
  color: #000000;
  background-color: #6FB1C8;
  font-family: "roboto", sans-serif;
  font-weight: 600;
  padding: 0.75rem 6rem 0.75rem 4rem;
  margin-right: 4rem;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.lists .list-section .anchor-container a::before {
  content: "";
  width: 2rem;
  height: 2rem;
  background-image: url("/Assets/icons/cta-icon.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 3rem;
}
.lists .list-section .anchor-container a:nth-of-type(1)::before {
  background-image: url("/Assets/icons/cart-icon.png");
}
.lists .list-section .anchor-container a:nth-of-type(2)::before {
  background-image: url("/Assets/icons/search-icon.png");
}
.lists .list-section .list-section-img--alt {
  position: initial;
  left: -5rem;
}
@media (min-width: 768px) {
  .lists .list-section .list-section-img--alt {
    position: absolute;
  }
}
@media (min-width: 1024px) {
  .lists .list-section .list-section-img--alt {
    position: absolute;
  }
}
.lists .list-section .list-section-img--alt img {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .lists .list-section .list-section-img--alt img {
    width: 85%;
  }
}
@media (min-width: 1024px) {
  .lists .list-section .list-section-img--alt img {
    width: 95%;
  }
}
@media (min-width: 1200px) {
  .lists .list-section .list-section-img--alt img {
    width: 100%;
  }
}
.lists .list-section .list-section-img img {
  width: 100%;
  height: auto;
}

.graphic-section {
  background-color: #003057;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  padding: 2rem 0 6rem 0;
}
@media (min-width: 768px) {
  .graphic-section {
    flex-direction: row;
    height: 72rem;
    padding: 0;
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .graphic-section {
    height: 67rem;
  }
}
@media (min-width: 1200px) {
  .graphic-section {
    height: 70rem;
  }
}
@media (min-width: 1440px) {
  .graphic-section {
    height: 67rem;
  }
}
@media (min-width: 768px) {
  .graphic-section .graphic-copy {
    width: 60%;
    display: flex;
    flex-direction: row;
    padding-top: 2rem;
  }
}
@media (min-width: 768px) {
  .graphic-section .graphic-copy .copy-container {
    max-width: 40%;
    margin: 0 auto;
  }
}
.graphic-section .graphic-copy .copy-container h2 {
  font-size: 5.25rem;
  font-weight: 600;
  font-family: "roboto", sans-serif;
  color: #6FB1C8;
  line-height: 1;
  margin-top: 2rem;
}
.graphic-section .graphic-copy .copy-container .h2-spanish {
  font-size: 4.5rem;
  margin-bottom: 1rem;
}
.graphic-section .graphic-copy .copy-container p {
  display: none;
  color: #ffffff;
}
@media (min-width: 768px) {
  .graphic-section .graphic-copy .copy-container p {
    display: block;
  }
}
.graphic-section .graphic-copy .copy-container a {
  display: none;
}
@media (min-width: 768px) {
  .graphic-section .graphic-copy .copy-container a {
    display: inline-block;
  }
}
.graphic-section .graphic-img {
  width: 100%;
}
@media (min-width: 768px) {
  .graphic-section .graphic-img {
    width: 45rem;
    position: absolute;
    left: 38%;
    top: 45rem;
  }
}
@media (min-width: 1440px) {
  .graphic-section .graphic-img {
    left: 45%;
  }
}
.graphic-section .graphic-img img {
  width: 100%;
}
.graphic-section .img-container {
  border-bottom: 4px solid #FEED01;
}
@media (min-width: 768px) {
  .graphic-section .img-container {
    width: 40%;
    border-bottom: none;
    border-left: 4px solid #FEED01;
    height: 100%;
  }
}
.graphic-section .img-container img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .graphic-section .img-container img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.graphic-section .copy-container-mobile {
  width: 80%;
  margin: 0 auto;
  color: #ffffff;
  padding: 2rem 0;
  display: block;
}
@media (min-width: 768px) {
  .graphic-section .copy-container-mobile {
    display: none;
  }
}
.graphic-section .copy-container-mobile p {
  margin-bottom: 5rem;
}
.graphic-section a {
  text-decoration: none;
  font-size: 2.2rem;
  color: #000000;
  background-color: #6FB1C8;
  font-family: "roboto", sans-serif;
  font-weight: 600;
  padding: 0.75rem 4rem;
}
@media (min-width: 768px) {
  .graphic-section a {
    font-size: 2.1rem;
  }
}