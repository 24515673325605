.info-section {
    width: 100%;
    background-color: #003057;
    margin-top: .5rem;

    .info-container {
        width: 90%;
        margin: 0 auto;
        padding: 2rem 0;
        display: flex;
        flex-direction: column;

        @include media(">=tablet") {
            max-width: 1080px;
        }

        .info-copy-container {
            display: flex;
            flex-direction: column-reverse;

            @include media(">=tablet") {
                flex-direction: row;
            }

            .info-copy {
                color: $white;
                text-align: center;
                padding: 0 1rem;

                @include media(">=tablet") {
                    text-align: left;
                    width: 80%;
                }
                
                h3 {
                    font-size: 3.6rem;
                    font-weight: 600;
                    line-height: 1;
                    color: $viega-light-blue;
                    margin: 1rem 0 0 0;
                }

                p {
                    line-height: 1.5;

                    &:nth-of-type(1){
                        margin-top: .5rem;
                        margin-bottom: 4rem;

                        @include media(">=tablet") {
                            margin-bottom: 2rem;
                        }
                    }

                    &:nth-of-type(2) {
                        font-weight: 600;
                        max-width: 75%;
                        margin: 0 auto;

                        @include media(">=tablet") {
                            margin: initial;
                            width: initial;
                        }
                    }
                }
            }

            .info-icon {
                width: 16rem;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .icon-section {
            text-align: center;
            display: flex;
            flex-direction: column;
            margin-top: 5rem;

            @include media(">=tablet") {
                text-align: left;
            }

            @include media(">=desktop") {
                flex-direction: row;
                margin-top: 3rem;
                text-align: left;
                justify-content: space-between;
            }

            .icon-container {
                margin-bottom: 6rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include media(">=tablet") {
                    flex-direction: row;
                    margin-bottom: 2rem;
                }

                @include media(">=desktop") {
                    margin-bottom: initial;
                }

                .icon {
                    width: 10rem;

                    @include media(">=tablet") {
                        width: 7rem;
                        padding: 0 1rem;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .icon-copy {
                    color: $viega-light-blue;

                    a {
                        text-decoration: none;
                        color: $viega-light-blue;
                    }

                    p {
                        margin: 0.5rem 0;
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}