header {
    background-color: $viega-blue;
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem;

    @include media(">=tablet") {
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: center;
    }

    .lang-anchors {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 12rem;

        // &--alt {
        //     width: 10rem;
        // }

        .dropdown-content {
            display: none;
            position: relative;
            top: inherit;
            right: 0;
            width: 100%;

            @include media(">=tablet") {
                position: absolute;
                top: 42px;
            }
        }

        .active {
            display: block;
        }

        &:hover {
            .lang-anchor--primary {
                &::before {
                    transform: rotate(-90deg);
                    transition: ease .25s;
                }
            }
        }
    }

    .lang-anchor {
        color: $viega-light-blue;
        display: inline-flex;
        font-family: 'roboto', sans-serif;
        font-size: 1.3rem;
        font-weight: 600;
        text-decoration: none;
        margin-left: auto;
        border: 3px solid $viega-light-blue;
        padding: .5rem 4rem .5rem 2rem;
        position: relative;
        align-items: center;
        transition: ease-in-out .25s;
        background: transparent;
        width: 100%;
        margin: .25rem 0;

        @include media(">=tablet") {
            margin-left: initial;
            margin: 0;
        }

        &--primary {
            &::before {
                content: '';
                width: 1rem;
                height: 1rem;
                background-image: url('/Assets/icons/cta-icon.png');
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                right: 2rem;
            }
        }
    }

    .logo-container {
        display: flex;
        flex-direction: row;
        margin: 4rem auto 2rem auto;
        align-items: center;
        justify-content: space-between;

        @include media(">=tablet") {
            margin: 1rem 10rem 1rem 0;
        }

        .logo {
            width: 45%;

            &:nth-child(1) {
                border-right: 1px solid $white;
                padding-right: 1.5rem;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}