.graphic-section {
    background-color: $viega-blue;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    padding: 2rem 0 6rem 0;

    @include media(">=tablet") {
        flex-direction: row;
        height: 72rem;
        padding: 0;
        text-align: left;
    }

    @include media(">=desktop") {
        height: 67rem;
    }

    @include media(">=widescreen") {
        height: 70rem;
    }

    @include media(">=hugescreen") {
        height: 67rem;
    }

    .graphic-copy {
        
        @include media(">=tablet") {
            width: 60%;
            display: flex;
            flex-direction: row;
            padding-top: 2rem;
        }

        .copy-container {

            @include media(">=tablet") {
                max-width: 40%;
                margin: 0 auto;
            }

            h2 {
                font-size: 5.25rem;
                font-weight: 600;
                font-family: 'roboto', sans-serif;
                color: $viega-light-blue;
                line-height: 1;
                margin-top: 2rem;
            }

            .h2-spanish {
                font-size: 4.5rem;
                margin-bottom: 1rem;
            }

            p {
                display: none;
                color: $white;

                @include media(">=tablet") {
                    display: block;
                }
            }

            a { 
                display: none;

                @include media(">=tablet") {
                    display: inline-block;
                }
            }
        }
    }

    .graphic-img {
        width: 100%;

        @include media(">=tablet") {
            width: 45rem;
            position: absolute;
            left: 38%;
            top: 45rem;
        }

        @include media(">=hugescreen") {
            left: 45%;
        }

        img {
            width: 100%;
        }
    }

    .img-container {
        border-bottom: 4px solid $viega-yellow;

        @include media(">=tablet") {
            width: 40%;
            border-bottom: none;
            border-left: 4px solid $viega-yellow;
            height: 100%;
        }

        img {
            width: 100%;
            height: auto;

            @include media(">=tablet") {
               height: 100%;
               object-fit: cover;
               object-position: center;
            }
        }
    }

    .copy-container-mobile {
        width: 80%;
        margin: 0 auto;
        color: $white;
        padding: 2rem 0;
        display: block;

        @include media(">=tablet") {
            display: none;
        }

        p {
            margin-bottom: 5rem;
        }
    }

    a {
        text-decoration: none;
        font-size: 2.2rem;
        color: $black;
        background-color: $viega-light-blue;
        font-family: 'roboto', sans-serif;
        font-weight: 600;
        padding: .75rem 4rem;

        @include media(">=tablet") {
            font-size: 2.1rem;
        }
    } 
}