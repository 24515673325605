$white: #ffffff;
$black: #000000;
$viega-blue: #003057;
$viega-light-blue: #6FB1C8;
$fleet-dark-blue: #2b3459;
$viega-yellow: #FEED01;
$fleet-light-green: #00b350;
$fleet-grey: #555555;
$dark-grey: #4A4F54;
$light-grey: #E6E6E6;
$grey: #707070;

html {
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
}

body {
	position: relative;

	&.no-scroll {
		overflow: hidden;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}