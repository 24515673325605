.lists {
    background-color: $black;

    &--alt {
        min-height: 60rem;
        background-color: $viega-blue;
        display: flex;
        padding-top: 4rem;

        @include media(">=tablet") {
            padding: 0;
        }
    }

    .list-section {
        width: 85%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;

        @include media(">=tablet") {
            flex-direction: row-reverse;
            max-width: 1080px;
            justify-content: space-between;
            align-items: flex-end;
        }


        .list-container {

            @include media(">=tablet") {
                max-width: 60%;
            }

            &--alt {
                max-width: 90%;
                align-self: center;

                @include media(">=tablet") {
                    max-width: 70%
                }
            }

            .list-container-copy {
                color: $white;
                text-align: center;
                font-family: 'roboto', sans-serif;

                @include media(">=tablet") {
                    text-align: left;
                }

                h3 {
                    font-family: 'roboto', sans-serif;
                    font-size: 3.6rem;
                    color: $viega-yellow;
                    font-weight: 600;
                }

                p {
                    font-family: 'roboto', sans-serif;
                    font-size: 1.6rem;
                }

                &--alt {

                    h3 {
                        color: $viega-light-blue;
                        font-size: 3.6rem;
                        margin: 1rem 0;
                    }
                }
            }

            .lists-container {

                &--alt {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @include media(">=tablet") {
                        flex-direction: row;
                    }
                }

                .list-item {
                    color: $white;
                    text-align: center;
                    margin: 2rem 0;
                    display: flex;
                    flex-direction: column;

                    @include media(">=tablet") {
                        flex-direction: row;
                        text-align: left;
                    }

                    &--alt {
                        flex-direction: column;
                        text-align: center;
                        width: initial;
                        border-right: none;

                        &:nth-of-type(3) {
                            border: none;
                        }

                        @include media(">=tablet") {
                            width: 25%;
                            border-right: 1px solid #EAEAEA;
                            padding-right: 3rem;
                        }
                    }

                    .list-img {
                        margin: 2rem 0;

                        @include media(">=tablet") {
                            margin: 0 2rem 0 0;
                        }

                        &--alt {
                            margin: 1rem 0;
                        }
                    }

                    h4 {
                        font-size: 1.6rem;
                        font-weight: 600;
                        margin: 0;
                    }

                    p {
                        font-size: 1.6rem;
                        margin: 0;
                    }
                }
            }
        }

        .anchor-container {
            display: none;
            margin-top: 5rem;

            @include media(">=tablet") {
                display: block;
            }

            a {
                text-decoration: none;
                font-size: 2.4rem;
                color: $black;
                background-color: $viega-light-blue;
                font-family: 'roboto', sans-serif;
                font-weight: 600;
                padding: .75rem 6rem .75rem 4rem;
                margin-right: 4rem;
                display: inline-flex;
                align-items: center;
                position: relative;

                &::before {
                    content: '';
                    width: 2rem;
                    height: 2rem;
                    background-image: url('/Assets/icons/cta-icon.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    position: absolute;
                    right: 3rem;
                }

                &:nth-of-type(1) {

                    &::before {
                        background-image: url('/Assets/icons/cart-icon.png');
                    }
                }

                &:nth-of-type(2) {

                    &::before {
                        background-image: url('/Assets/icons/search-icon.png');
                    }
                }
            }
        }

        .list-section-img {
            &--alt {
                position: initial;
                left: -5rem;

                @include media(">=tablet") {
                    position: absolute;
                }

                @include media(">=desktop") {
                    position: absolute;
                }

                img {
                    width: 100%;
                    height: 100%;

                    @include media(">=tablet") {
                        width: 85%;
                    }

                    @include media(">=desktop") {
                        width: 95%;
                    }

                    @include media(">=widescreen") {
                        width: 100%;
                    }
                }
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}