.banner {
    width: 100%;
    background-color: $viega-yellow;
    padding: 4rem 0;
    margin-top: .5rem;

    @include media(">=tablet") {
        padding: 2rem 0;
    }

    .banner-copy-container {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-direction: column;

        @include media(">=tablet") {
            max-width: 1080px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .banner-copy {
            text-align: center;

            @include media(">=tablet") {
                text-align: left;
                width: 50%;
            }

            h3 {
                font-size: 3.6rem;
                font-weight: 600;
                margin: 0 0 1rem 0;

                
                @include media(">=tablet") {
                    margin: 0;
                }
            }

            p {
                font-size: 1.8rem;
                line-height: 1.75;

                @include media(">=tablet") {
                    margin: 0;
                    line-height: 1;
                }
            }
        }

        .banner-cta {
            color: $viega-yellow;
            background-color: $black;
            text-transform: uppercase;
            text-decoration: none;
            font-family: 'roboto', sans-serif;
            font-size: 1.8rem;
            font-weight: 600;
            padding: 1rem 1.5rem;

            @include media(">=tablet") {
                max-width: 35%;
                margin: 0 auto;
            }
        }
    }
}

.banner-full {
    width: 100%;
    background: url('/Assets/mobile-banner-new.jpg'), linear-gradient(rgba(0,0,0,.9),rgba(0,0,0,0));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 47rem;
    background-blend-mode: overlay;
    display: flex;
    align-items: center;
    margin: .5rem 0;

    @include media(">=tablet") {
        background: url('/Assets/Viega-group-shot.jpg'), linear-gradient(to right,rgba(0,0,0,.9),rgba(0,0,0,0));
    }

    &-copy {
        color: $white;
        width: 87%;
        margin: 0 auto;
        text-align: center;

        @include media(">=tablet") {
            text-align: left;
        }

        @include media(">=hugescreen") {
            width: 63%;
        }
        
        h2 {
            font-size: 3.6rem;
            margin: 0;
            line-height: 1;
            font-weight: 300;
            font-family: 'roboto', sans-serif;

            @include media(">=tablet") {
                font-size: 6rem;
            }

            &:nth-of-type(2) {
                font-weight: 600;
                margin-bottom: 4rem;
            }
        }

        a {
            text-decoration: none;
            font-size: 2.4rem;
            color: $black;
            background-color: $viega-light-blue;
            font-family: 'roboto', sans-serif;
            font-weight: 600;
            padding: .75rem 4rem;
        }
    }
}