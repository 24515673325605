.brands-container {
    width: 90%;
    margin: 2rem auto;

    @include media(">=tablet") {
        max-width: 1080px;
        margin: 5rem auto;
    }

    @include media(">=desktop") {
        width: 70%;
        margin: 5rem auto;
    }

    &-header {
        text-align: center;
        border-bottom: 2px solid $viega-light-blue;

        @include media(">=tablet") {
            border-bottom: none;
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }

        hr {
            display: none;

            @include media(">=tablet") {
                display: block;
                height: 2px;
                background-color: $viega-light-blue;
                border: none;
                width: 100%;
            }
        }

        h2 {
            font-size: 3.6rem;
            font-weight: 600;
            color: $viega-blue;
            margin: 1rem 0;
            
            @include media(">=tablet") {
                min-width: fit-content;
                margin: 0;
            }
        }
    }

    .brands-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .brand {
            width: 40%;
            margin: 3rem auto;

            @include media(">=tablet") {
                width: 14%;
                margin: 0 auto;
                padding: 3rem;
            }

            @include media(">=desktop") {
                width: 16%;
            }

            @include media(">=widescreen") {
                width: 18%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}